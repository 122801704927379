import {
  Box,
  Checkbox,
  Divider,
  HStack,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { Alert, Button, IconButton, Infobox, Tag, TruncatableText, Tooltip } from 'Atoms';
import {
  EsrsAssessmentDocument_,
  GetRequiredTagsMaterialMetricsDocument_,
  GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
  MaterialMetricsPerDisclosureDocument_,
  QuestionType_Enum_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useDeleteMaterialMetricTagValueMutation,
  useUpdateSubsidiariesMaterialityMutation,
  useUpsertMaterialMetricsMutation,
} from 'models';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Typography } from 'Tokens';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { HelpIcon } from 'Tokens/Icons/Status';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { uniq, uniqBy } from 'lodash';
import {
  DataCollectionLevel,
  DataCollectionRowEnum,
  FREQUENCY_OPTIONS,
  GROUP_DATA_GATHERING_OPTIONS,
  SUBSIDIARY_DATA_GATHERING_OPTIONS,
  TableMetricData,
} from '../DataCollection.d';
import { MetricNameWithTag } from '../DataCollectionUtils';
import { useEsrsMetricData } from '../../DisclosureRequirements/Metrics/Metrics.hooks';
import { getDataCollectionText } from 'containers/Esrs/utils';
import { MenuSelector } from 'Molecules/Menu/MenuSelector';
import { useToast } from 'utils/hooks';
import { Modal } from 'Molecules';
import { FrequencyEnums } from '../../DisclosureRequirements';
import { useTranslation } from 'utils/translation';
import { hasDataCollectionGroup } from '../DataCollection.hooks';

const getChildrenRowsWithoutParents = (row: TableMetricData) => {
  const childrenRows: TableMetricData[] = [];
  const isBoolean = row.metricType === QuestionType_Enum_.YesNo_;

  const recurseRows = (subRows: TableMetricData[]) => {
    if (!!subRows.length) {
      subRows.forEach((subRow) => {
        if (!subRow.subRows?.length) {
          childrenRows.push(subRow);
        } else recurseRows(subRow.subRows ?? []);
      });
    }
  };

  if (row?.subRows?.length) {
    if (isBoolean) childrenRows.push(row);
    recurseRows(row?.subRows);
  }

  return uniqBy(childrenRows, 'reference');
};

const getIsIndeterminate = (
  row: TableMetricData,
  selectedRows: TableMetricData[],
  childrenRows: TableMetricData[]
) => {
  if (!row.subRows?.length) return false;
  const areAllChildrenSelected = childrenRows.every((r) =>
    selectedRows.some((sr) => sr.reference === r.reference)
  );
  if (areAllChildrenSelected) return false;

  const areSomeChildrenSelected = childrenRows.some((r) =>
    selectedRows.some((sr) => sr.reference === r.reference)
  );
  return areSomeChildrenSelected;
};

export const MetricRefOrderNumber = ({ row }: { row: Row<TableMetricData> }) => {
  const metric = useMemo(() => row.original, [row]);
  const { metricRefNumber } = useEsrsMetricData(metric.reference, metric);

  return <TruncatableText text={metricRefNumber} variant="body" />;
};

export const MetricShortTitleView = ({
  row,
  companyStandardId,
  isGroupCompany,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
  isGroupCompany: boolean;
}) => {
  const [rowData] = useMemo(() => {
    return [row.original];
  }, [row]);

  const getTags = (
    metric: TableMetricData
  ): {
    combinedTags: string[];
    configuredTagsWithValues: {
      type: string;
      values: string[];
    }[];
    unconfiguredTags: string[];
  } => {
    const materialMetric = metric.materialMetrics.find(
      (mm) => mm.materialStandardId === companyStandardId
    );
    const adminPanelTags = metric.adminPanelTags?.map((tag) => tag.type) ?? [];
    const materialMetricTags = materialMetric?.materialMetricTags.map((tag) => tag.tagType) ?? [];

    const combinedTags = uniq([...adminPanelTags, ...materialMetricTags]);

    // Configured tag values (used to be displayed in tooltip)
    const configuredTagsWithValues =
      combinedTags.map((tag) => ({
        type: tag,
        values:
          materialMetric?.materialMetricTags
            .find((t) => t.tagType === tag)
            ?.materialTagValues?.map((v) => v.tagValue) ?? [],
      })) ?? [];

    // Breakdowns (used to color unconfigured tags)
    const addedBreakdowns =
      materialMetric?.materialMetricTags.filter((tag) => tag.materialTagValues.length > 0) ?? [];

    const requiredBreakdowns = metric.adminPanelTags.filter((tag) => !tag.isOptional) ?? [];

    const remainingBreakdowns = requiredBreakdowns.filter(
      (required) =>
        !addedBreakdowns.find((added) => added.tagType === required.type) &&
        (hasDataCollectionGroup(rowData) || !isGroupCompany)
    );

    const unconfiguredTags = remainingBreakdowns.map((t) => t.type);

    // Recursively get tags
    if (metric.isChild && !combinedTags.length && !!metric.parentMetric) {
      return getTags(metric.parentMetric as TableMetricData);
    }

    return { combinedTags, configuredTagsWithValues, unconfiguredTags };
  };

  const tags = useMemo(() => getTags(rowData), [rowData]);

  const metricNameRef = useRef<HTMLDivElement>(null);

  const getMetricIcon = () => {
    if (rowData.metricType === QuestionType_Enum_.LongText_) {
      return <MetricTypeIcon type={MetricTypes.text} />;
    }
    if (rowData.metricType === QuestionType_Enum_.YesNo_) {
      return <MetricTypeIcon type={MetricTypes.boolean} />;
    }
    return <MetricTypeIcon type={MetricTypes.number} />;
  };

  return (
    <HStack pl={`${row.depth * 24}px`} spacing="8px" width="100%">
      {row.getCanExpand() ? (
        <IconButton
          variant={'ghost'}
          size="xs"
          onClick={row.getToggleExpandedHandler()}
          aria-label="expand"
          icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
        />
      ) : (
        getMetricIcon()
      )}

      <Box ref={metricNameRef} width="100%">
        <MetricNameWithTag
          name={rowData.shortTitle ?? rowData.title}
          tags={rowData.type === DataCollectionRowEnum.metric ? tags.combinedTags : []}
          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
          unconfiguredTags={tags.unconfiguredTags}
          configuredTagsWithValues={tags.configuredTagsWithValues}
        />
      </Box>
    </HStack>
  );
};

export const DataCollectionCheckboxHeader = React.memo(
  ({
    tableRows,
    selectedRows,
    disclosureRequirementRef,
    setSelectedRows,
  }: {
    tableRows: TableMetricData[];
    selectedRows: TableMetricData[];
    disclosureRequirementRef: string;
    setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  }) => {
    const allRows = uniqBy(
      tableRows
        .filter((row) => row.disclosureRequirementRef === disclosureRequirementRef)
        ?.flatMap((row) => (row.subRows?.length ? getChildrenRowsWithoutParents(row) : row)) ?? [],
      'reference'
    );
    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          isDisabled={!tableRows.length}
          isChecked={
            selectedRows.length === allRows?.length &&
            selectedRows[0]?.disclosureRequirementRef === disclosureRequirementRef
          }
          isIndeterminate={
            selectedRows.length !== 0 &&
            selectedRows.length < allRows?.length &&
            selectedRows[0]?.disclosureRequirementRef === disclosureRequirementRef
          }
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setSelectedRows(allRows);
            } else {
              setSelectedRows([]);
            }
          }}
        />
      </Box>
    );
  }
);

export const DataCollectionCheckboxCell = React.memo(
  ({
    row,
    selectedRows,
    setSelectedRows,
    disclosureRequirementRef,
  }: {
    row: Row<TableMetricData>;
    selectedRows: TableMetricData[];
    disclosureRequirementRef: string;
    setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  }) => {
    const rowData = useMemo(() => row.original, [row]);
    const childrenRows = getChildrenRowsWithoutParents(rowData);
    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          isChecked={
            rowData.subRows?.length
              ? selectedRows.some((r) => childrenRows.some((cr) => cr.reference === r.reference))
              : selectedRows.some((sr) => sr.reference === rowData.reference)
          }
          onChange={(e) => {
            console.log(selectedRows);
            if (e.currentTarget.checked) {
              if (
                selectedRows.length > 0 &&
                selectedRows[0].disclosureRequirementRef !== disclosureRequirementRef
              ) {
                setSelectedRows([row.original]);
              } else {
                if (rowData.adminPanelTags.some((tag) => !tag.isOptional)) {
                  setSelectedRows((cur) => [...cur, rowData, ...childrenRows]);
                } else if (!!rowData.subRows?.length) {
                  setSelectedRows((cur) => [...cur, ...childrenRows]);
                } else setSelectedRows((cur) => [...cur, rowData]);
              }
            } else if (!!rowData.subRows?.length) {
              if (rowData.adminPanelTags.some((tag) => !tag.isOptional)) {
                setSelectedRows((cur) => [
                  ...cur
                    .filter((item) => !(rowData.reference === item.reference))
                    .filter((item) => !childrenRows.some((r) => r.reference === item.reference)),
                ]);
              } else {
                setSelectedRows((cur) => [
                  ...cur.filter(
                    (item) => !childrenRows.some((r) => r.reference === item.reference)
                  ),
                ]);
              }
            } else
              setSelectedRows((cur) => [
                ...cur.filter((item) => item.reference !== rowData.reference),
              ]);
          }}
          isIndeterminate={getIsIndeterminate(rowData, selectedRows, childrenRows)}
          isDisabled={!!rowData.parentMetric?.adminPanelTags.length}
        />
      </Box>
    );
  }
);

export const ParentMetricConfigMenu = React.memo(
  ({
    row,
    selectedRows,
    setSelectedRows,
    tagText,
  }: {
    row: Row<TableMetricData>;
    selectedRows: TableMetricData[];
    setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
    tagText: string;
  }) => {
    const [showAll, setShowAll] = useState(false);

    const rowData = useMemo(() => row.original, [row]);
    return (
      <Menu>
        <MenuButton as={Tag} clickable variant="default">
          {tagText}
        </MenuButton>
        <MenuList p="8px" w="280px">
          <VStack spacing="4px" alignItems="start" w="100%">
            <Typography variant="h4" color="text.default">
              {' '}
              Data point settings
            </Typography>
            <VStack spacing="0px" alignItems="start">
              <Typography variant="body">
                To change this data point, select all sub data points.{!showAll && '... '}
                {!showAll && (
                  <Typography
                    variant="bodyStrong"
                    color="text.muted"
                    cursor="pointer"
                    as="span"
                    onClick={() => setShowAll(true)}
                  >
                    Show more
                  </Typography>
                )}
                {showAll && 'This data point is derived from multiple sub data points.'}
              </Typography>
              {showAll && (
                <>
                  <br />
                  <Typography variant="body">
                    You can adjust all or some of these sub data points. To modify individual sub
                    data points, expand the hierarchy on the right and access each setting.
                  </Typography>
                  {showAll && (
                    <Typography
                      variant="bodyStrong"
                      color="text.muted"
                      cursor="pointer"
                      as="span"
                      mt="8px"
                      onClick={() => setShowAll(false)}
                    >
                      Show less
                    </Typography>
                  )}
                </>
              )}
            </VStack>
          </VStack>
          <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
          <Box w="100%">
            <Button
              variant="secondary"
              w="100%"
              onClick={() => {
                const childrenRows = getChildrenRowsWithoutParents(rowData);
                const unselectedRows = childrenRows.filter(
                  (cr) => !selectedRows.some((r) => r.reference === cr.reference)
                );
                setSelectedRows((cur) => [...cur, ...unselectedRows]);
              }}
            >
              Select all sub data points
            </Button>
          </Box>
        </MenuList>
      </Menu>
    );
  }
);

export const MultipleMetricsParentsModal = ({
  metricTitle,
  parentMetrics,
  isOpen,
  onClose,
}: {
  metricTitle: string;
  parentMetrics: TableMetricData['parentMetrics'];
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={metricTitle} hasFooter={false}>
      <Box w="100%">
        <Infobox
          status="neutral"
          title="Important"
          closable={false}
          description={
            <VStack spacing="0px" alignItems="start">
              <Typography variant="body">
                Note that this metric is used to calculate several other metrics, so changing the
                settings here will propagate throughout the system. Below is the full list of
                metrics that rely on this metric.
              </Typography>
              <br />
              <UnorderedList>
                {parentMetrics.map((parent) => (
                  <ListItem>{parent.parentMetric.title}</ListItem>
                ))}
              </UnorderedList>
            </VStack>
          }
        />
      </Box>
    </Modal>
  );
};

export const DataCollectionDataGatheringLevelCell = ({
  row,
  companyStandardId,
  isGroup,
  selectedRows,
  setSelectedRows,
  hasReportingUnits,
  isMetricDisclosure,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
  isGroup: boolean;
  selectedRows: TableMetricData[];
  setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  hasReportingUnits: boolean;
  isMetricDisclosure: boolean;
}) => {
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();
  const [updateSubsidiariesMateriality] = useUpdateSubsidiariesMaterialityMutation();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('esrs');

  const materialMetric = useMemo(() => {
    const materialMetrics = row.original.materialMetrics;
    return materialMetrics.find((mm) => mm.materialStandardId === companyStandardId);
  }, [row]);

  const parentMetrics = useMemo(() => {
    return row.original.parentMetrics;
  }, [row]);

  const datacollectionText = useMemo(
    () => getDataCollectionText(materialMetric?.dataCollection ?? '', isGroup),
    [materialMetric, isGroup]
  );

  const existingMaterialTags = materialMetric?.materialMetricTags.flatMap((materialTag) =>
    materialTag.materialTagValues.map((t) => ({
      tagType: t.tagType,
      tagValue: t.tagValue,
    }))
  );

  if (
    (!isMetricDisclosure && !isGroup) ||
    row.original.type == DataCollectionRowEnum.tag ||
    !!row.original.parentMetric?.adminPanelTags.length
  ) {
    return (
      <Tag variant="default" disabled truncatable>
        <Tooltip
          label={`This data point can only be collected on a ${datacollectionText.toLowerCase()}`}
          minW={212}
        >
          {datacollectionText}
        </Tooltip>
      </Tag>
    );
  }

  if (!!row.original.childrenMetrics?.length) {
    const allRows = getChildrenRowsWithoutParents(row.original);
    const allRowsDataCollection =
      allRows.map(
        (r) =>
          r.materialMetrics?.find((mm) => mm.materialStandardId === companyStandardId)
            ?.dataCollection ?? ''
      ) ?? [];

    const dataCollectionText = allRowsDataCollection.every(
      (data) => data === allRowsDataCollection?.[0]
    )
      ? getDataCollectionText(allRowsDataCollection?.[0], isGroup)
      : 'Mixed';

    return (
      <ParentMetricConfigMenu
        row={row}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tagText={dataCollectionText}
      />
    );
  }

  const onChange = async (selectedDataCollection: string) => {
    const deleteAllGroupTags =
      isGroup &&
      selectedDataCollection === DataCollectionLevel.subsidiaries &&
      materialMetric?.dataCollection === DataCollectionLevel.group;

    try {
      await upsertMaterialMetric({
        variables: {
          objects: [
            {
              id: materialMetric?.id,
              frequency: materialMetric?.frequency,
              isMaterial: materialMetric?.isMaterial,
              dataCollection: selectedDataCollection,
              materialStandardId: companyStandardId,
              metricRef: row.original.reference,
            },
          ],
        },
        refetchQueries: [
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
          GetRequiredTagsMaterialMetricsDocument_,
          GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
        ],
      });
      if (deleteAllGroupTags && existingMaterialTags?.length) {
        // delete tags
        existingMaterialTags?.forEach((tag) => {
          deleteMaterialMetricTagValue({
            variables: {
              materialMetricId: materialMetric.id,
              tagType: tag.tagType,
              tagValue: tag.tagValue,
            },
            refetchQueries: [MaterialMetricsPerDisclosureDocument_, EsrsAssessmentDocument_],
          });
        });
      }

      if (
        selectedDataCollection === DataCollectionLevel.subsidiaries &&
        selectedDataCollection !== materialMetric?.dataCollection
      ) {
        await updateSubsidiariesMateriality({
          variables: { id: companyStandardId },
        });
      }

      toast({
        text: 'Data collection level updated',
      });
    } catch (error) {
      toast({
        text: 'Unable to configure data collection level',
        variant: 'danger',
      });
      console.error(error);
      throw new Error('Unable to configure data collection level');
    }
  };

  const notAggregatableWarningtext = useMemo(
    () =>
      isGroup
        ? t('assessment.nonAggregatableAlert.groupDropdownWarningtext')
        : t('assessment.nonAggregatableAlert.companyDropdownWarningText'),
    [isGroup]
  );

  // if (isChild && isParentMetricMaterial)
  //   return (
  //     <HStack>
  //       <ArrowCornerDownRight color="inherit" />
  //       <Typography variant="body" color="inherit">
  //         {getDataCollectionText(materialMetric?.dataCollection ?? '')}
  //       </Typography>
  //     </HStack>
  //   );

  return (
    // <HStack>
    //   {!!dataCollection && DataCollectionLabelOptions[dataCollection as DataCollectionType]?.icon}
    //   <Typography variant="body">
    //     {getDataCollectionText(materialMetric?.dataCollection ?? '')}
    //   </Typography>
    // </HStack>
    <>
      <MenuSelector
        isTagMenuButton
        menuWidth="236px"
        options={isGroup ? GROUP_DATA_GATHERING_OPTIONS : SUBSIDIARY_DATA_GATHERING_OPTIONS}
        isSingleSelect
        data={[
          materialMetric?.dataCollection ??
            (isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.company),
        ]}
        isNonAggregatable={row.original.notAggregatable}
        notAggregatableWarningText={notAggregatableWarningtext}
        handleChange={(val) => {
          onChange(
            val?.[0] ?? (isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.company)
          );
        }}
        isOptionDisabled={(val) => !hasReportingUnits && val === DataCollectionLevel.reportingUnits}
        additionalContent={
          parentMetrics.length > 1 ? (
            <Alert status="warning" closable={false}>
              <HStack my="-4px" mr="-4px" spacing="0px">
                <Typography variant="body">Affects several metrics</Typography>
                <IconButton
                  variant="ghost"
                  aria-label="parent-metrics"
                  icon={<HelpIcon />}
                  size="sm"
                  onClick={onOpen}
                />
              </HStack>
            </Alert>
          ) : null
        }
      />

      <MultipleMetricsParentsModal
        isOpen={isOpen}
        onClose={onClose}
        metricTitle={row.original.shortTitle ?? row.original.title}
        parentMetrics={parentMetrics}
      />
    </>
  );
};

export const DataCollectionFrequencyCell = ({
  row,
  companyStandardId,
  selectedRows,
  setSelectedRows,
  isMetricDisclosure,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
  selectedRows: TableMetricData[];
  setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  isMetricDisclosure: boolean;
}) => {
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const materialMetric = useMemo(() => {
    const materialMetrics = row.original.materialMetrics;
    return materialMetrics.find((mm) => mm.materialStandardId === companyStandardId);
  }, [row]);

  const onChange = (freq: string) => {
    try {
      upsertMaterialMetric({
        variables: {
          objects: [
            {
              id: materialMetric?.id,
              frequency: freq,
              isMaterial: materialMetric?.isMaterial,
              dataCollection: materialMetric?.dataCollection,
              materialStandardId: companyStandardId,
              metricRef: row.original.reference,
            },
          ],
        },
        refetchQueries: [
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      });
      toast({
        text: 'Data collection frequency updated',
      });
    } catch (error) {
      toast({
        text: 'Unable to configure data collection frequency',
        variant: 'danger',
      });
      console.error(error);
      throw new Error('Unable to configure data collection frequency');
    }
  };

  const parentMetrics = useMemo(() => {
    return row.original.parentMetrics;
  }, [row]);

  const isNarrativeMetric = useMemo(
    () => row.original.metricType !== QuestionType_Enum_.Decimal_,
    [row.original.metricType]
  );

  if (
    !isMetricDisclosure ||
    isNarrativeMetric ||
    row.original.type == DataCollectionRowEnum.tag ||
    !!row.original.parentMetric?.adminPanelTags.length
  )
    return (
      <Tag variant="default" disabled truncatable>
        <Tooltip label="This data point can only be collected once a year" minW={180}>
          {materialMetric?.frequency}
        </Tooltip>
      </Tag>
    );

  if (!!row.original.childrenMetrics?.length) {
    const allRows = getChildrenRowsWithoutParents(row.original);
    const allRowsFrequencies =
      allRows
        .map(
          (r) =>
            r.materialMetrics?.find((mm) => mm.materialStandardId === companyStandardId)?.frequency
        )
        .filter((f) => !!f) ?? [];

    // if (!allRowsFrequencies.length) return FrequencyEnums.yearly;

    const frequencyText = allRowsFrequencies.every((f) => f === allRowsFrequencies[0])
      ? allRowsFrequencies[0]
      : 'Mixed';

    return (
      <ParentMetricConfigMenu
        row={row}
        tagText={frequencyText ?? 'N/A'}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    );
  }

  // if (isChild && isParentMetricMaterial)
  //   return (
  //     <HStack>
  //       <ArrowCornerDownRight color="inherit" />
  //       <Typography variant="body" color="inherit">
  //         {materialMetric?.frequency}
  //       </Typography>
  //     </HStack>
  //   );

  return (
    <>
      <MenuSelector
        isTagMenuButton
        menuWidth="236px"
        options={FREQUENCY_OPTIONS}
        isSingleSelect
        data={[materialMetric?.frequency ?? FrequencyEnums.yearly]}
        handleChange={(freq) => {
          onChange(freq?.[0] ?? FrequencyEnums.yearly);
        }}
        additionalContent={
          parentMetrics.length > 1 ? (
            <Alert status="warning" closable={false}>
              <HStack my="-4px" mr="-4px" spacing="0px">
                <Typography variant="body">Affects several metrics</Typography>
                <IconButton
                  variant="ghost"
                  aria-label="parent-metrics"
                  icon={<HelpIcon />}
                  size="sm"
                  onClick={onOpen}
                />
              </HStack>
            </Alert>
          ) : null
        }
      />

      <MultipleMetricsParentsModal
        isOpen={isOpen}
        onClose={onClose}
        metricTitle={row.original.shortTitle ?? row.original.title}
        parentMetrics={parentMetrics}
      />
    </>
  );
};

export const DataCollectionLearnMoreCell = React.memo(
  ({
    row,
    setSelectedMetric,
    onDrawerOpen,
  }: {
    row: Row<TableMetricData>;
    setSelectedMetric: (metric: SelectedMetric) => void;
    onDrawerOpen: () => void;
  }) => {
    const rowData = useMemo(() => row.original, [row]);

    return (
      <IconButton
        aria-label="learn more"
        variant="ghost"
        icon={<HelpIcon />}
        size="md"
        onClick={() => {
          setSelectedMetric({
            reference: rowData.reference,
            description:
              rowData.type === DataCollectionRowEnum.tag
                ? 'This metric was generated by a breakdown of a parent metric'
                : (rowData.description ?? ''),
            tags: rowData.adminPanelTags.map((tag) => ({ type: tag.type })),
          });
          onDrawerOpen();
        }}
      />
    );
  }
);
