import { Box, HStack, VStack } from '@chakra-ui/react';
import { MetricTypes, MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { TableData } from 'Molecules/NestedTable';
import React, { useMemo, useRef, MutableRefObject } from 'react';
import { MetricsTableData } from '../../../MetricAnswers.hooks';
import { Row } from '@tanstack/react-table';
import { QuestionType_Enum_ } from 'models';
import { MetricNameWithTag } from 'containers/Esrs/pieces/DataCollection/DataCollectionUtils';
import {
  hasChildOnBULevel,
  hasChildOnSubLevel,
  hasQuarterlyFrequency,
} from '../../../AggregatedMetrics';
import { BooleanEnum } from 'Molecules';
import { IconButton } from '../../../../../../../../Atoms';
import { ChevronDownIcon, ChevronRightIcon } from '../../../../../../../../Tokens/Icons/Direction';

export const MetricRowTitle = ({
  row,
  rowData,
  onClick,
  isNarrative,
  isMissingTagOrNotAggregatable,
  hasOptedOut,
  isReport,
}: {
  row: Row<TableData<MetricsTableData>>;
  rowData?: MetricsTableData;
  onClick?: () => void;
  isNarrative?: boolean;
  isMissingTagOrNotAggregatable?: boolean;
  hasOptedOut?: boolean;
  isReport?: boolean;
}) => {
  const isRowNarrative = useMemo(
    () => row.original.metric.metricType !== QuestionType_Enum_.Decimal_,
    [row]
  );
  const materialMetric = useMemo(() => row.original.metric.materialMetrics?.[0], [row]);

  const [isCalculated, isChildMetric] = useMemo(() => {
    const isChild =
      row.getParentRow()?.original.tagName &&
      !row.getParentRow()?.original.tags?.length &&
      row.original.parentMetric;

    const calculated = row.getCanExpand() && !!row.original.metric.calculation;

    return [calculated, isChild];
  }, [row]);

  const rowType = useMemo(() => {
    if (row.original.locked) return MetricTypes.locked;
    if (isCalculated) return MetricTypes.calculated;
    if (isRowNarrative) return MetricTypes.text;
    return MetricTypes.number;
  }, [row, isCalculated, isRowNarrative]);

  const rowTitle = useMemo(() => {
    const unlockCondition = row.getParentRow()?.original.metric.unlockCondition
      ? BooleanEnum.True
      : BooleanEnum.False;

    if (row.original.isAdditionalInfo) return 'Additional information';
    if (row.original.locked)
      return `${row.original.lockedCount} more data points if selected ${unlockCondition} above`;
    if (isChildMetric) return row.original.metric.shortTitle ?? row.original.metric.title;
    if (row.original.tagName) return row.original.tagName ?? '';
    return row.original.metric.shortTitle ?? row.original.metric.title;
  }, [row]);

  const parentRowTitle = useMemo(() => {
    if (row.original.tagName) {
      return (
        row.getParentRow()?.original.tagName ??
        row.getParentRow()?.original.metric.shortTitle ??
        row.getParentRow()?.original.metric.title
      );
    }
  }, [row]);

  const showTags = useMemo(
    () => (row.original.tagType || !row.original.isChild) && !row.original.tagName,
    [row]
  );

  const materialMetricTags = useMemo(
    () => (showTags ? (materialMetric?.materialMetricTags?.map((tag) => tag.tagType) ?? []) : []),
    [materialMetric, showTags]
  );

  const metricNameRef = useRef<HTMLDivElement>(null);

  const calculateRowPadding = () => {
    if (isNarrative) return row.depth * 24;
    return row.depth * 24 + (row.getCanExpand() || !row.depth ? 0 : 44);
  };

  return (
    <HStack
      className="metricTitle"
      textDecoration="underline"
      textDecorationColor={row.original === rowData ? 'text.hint' : 'transparent'}
      textUnderlineOffset={row.original === rowData ? '2px' : '4px'}
      transition="0.15s"
      padding={isNarrative ? '14px' : undefined}
      pl={!isReport ? `${calculateRowPadding()}px` : ''}
      spacing="8px"
      cursor={row.original.isAdditionalInfo ? '' : 'pointer'}
      width="100%"
      alignItems="start"
      pb={isMissingTagOrNotAggregatable ? 0 : ''}
    >
      {isNarrative && (
        <MetricTypeIcon
          type={rowType}
          isBusinessUnits={hasChildOnBULevel(row.original.metric)}
          isSubsidiaries={hasChildOnSubLevel(row.original.metric)}
          isQuarterly={hasQuarterlyFrequency(row.original.metric)}
          calculation={row.original.metric.calculation ?? undefined}
          color={hasOptedOut ? 'text.hint' : 'text.default'}
        />
      )}
      <HStack
        spacing="8px"
        height="fit-content"
        id={
          !row.subRows.some((subrow) => subrow.original.referenceToSource)
            ? row.original.metric.reference
            : undefined
        }
      >
        {row.getCanExpand() && !isNarrative && (
          <Box onClick={(e) => e.stopPropagation()}>
            <IconButton
              variant={'ghost'}
              size="md"
              onClick={row.getToggleExpandedHandler()}
              aria-label="expand"
              icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
              color={hasOptedOut ? 'text.hint' : 'text.default'}
            />
          </Box>
        )}
        <VStack
          ref={metricNameRef}
          width="100%"
          onClick={onClick}
          height="fit-content"
          justifyContent="center"
          alignItems="start"
        >
          <MetricNameWithTag
            name={rowTitle}
            parentRowName={parentRowTitle}
            tags={materialMetricTags}
            textColor={
              row.original.locked ? 'text.disabled' : hasOptedOut ? 'text.hint' : undefined
            }
            rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
            isNarrative={isNarrative}
            isReport={isReport}
          />
        </VStack>
      </HStack>
    </HStack>
  );
};
